import React from 'react';

function LoadingDotsIcon() {
  return (
    <div
      className="dots-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)' // Optional: Add a semi-transparent background
      }}
    >
      <div></div>
    </div>
  );
}

export default LoadingDotsIcon;
